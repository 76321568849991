<template>
	<Component :is="doctype" v-if="doctype" v-bind="$data" />
</template>

<script>
import fetchUmbracoData from '@limbo-works/umbraco-client/client';
import { mapGetters } from 'vuex';

import doctypes, {
	getDoctypeFromSolutionAndTemplate,
} from '~/assets/js/doctypes';
import key from '~/assets/js/page-key';
import transition from '~/citi-baseline/assets/js/page-transition.js';
import {
	getAppFromRoute,
	comparePaths,
} from '~/citi-baseline/assets/js/utilities/helpers';

export default {
	name: 'WildcardPage',

	key: (route) => key(route),

	components: {
		...doctypes,
	},

	layout: ({
		store: {
			getters: { layout },
		},
	}) => layout,

	transition(to, from) {
		const fromComponent = getAppFromRoute(from);
		const solution = fromComponent?.$store?.getters?.solution;

		const searchPageUrl = fromComponent?.$store?.state.site?.searchPage.url;

		const fromSearchPage = comparePaths(
			from?.fullPath || '',
			searchPageUrl || ''
		);

		const toSearchPage = comparePaths(
			to?.fullPath || '',
			searchPageUrl || ''
		);

		if (['main', 'subsite'].includes(solution)) {
			return {
				name:
					fromSearchPage || toSearchPage
						? `t-search-${solution}`
						: `t-page-${solution}`,

				beforeLeave() {
					document.body.classList.add('js-page-transition');
					document.body.classList.add('js-page-transition--leave');
				},
				beforeEnter() {
					document.body.classList.remove('js-page-transition--leave');
					document.body.classList.add('js-page-transition--enter');
				},
				afterEnter() {
					document.body.classList.remove('js-page-transition--enter');
					document.body.classList.remove('js-page-transition');
				},
			};
		}

		return transition;
	},

	async asyncData({
		req,
		$config,
		error,
		redirect,
		route,
		store: { commit },
	}) {
		let fallbackHost = '';
		if (process.server) {
			fallbackHost = req.headers.host;
		} else {
			const { host } = new URL(window.location.href);
			fallbackHost = host;
		}

		const data = await fetchUmbracoData({
			endpointUrl: process.env.REDIS_URL
				? process.env.GET_DATA_ENDPOINT
				: undefined,
			onResponse(response) {
				commit('Cookies/SET_COOKIE', response?.headers?.['set-cookie']);
				return response;
			},
			error,
			params: {
				...route.query,
				...(process.env.NODE_ENV === 'development'
					? { cache: false }
					: {}),
				parts: ['content'],
				appHost: $config.APP_HOST || fallbackHost,
			},
			redirect,
			route,
		});

		// NOTE: if data is falsy, a redirect has taken place, or an error has been handled
		if (!data) {
			return;
		}

		const { content = {} } = data;

		if (content.template === 'ErrorPage') {
			commit('SET_ERROR_DATA', content);

			return;
		}

		commit('SET_TEMPLATE', content.template);
		commit('SET_PAGE_ID', content.id);
		commit('SET_EDITOR_LINK', content.editorLink);

		return content;
	},

	data() {
		return {
			isReducedMotion:
				this.$route.hash === '#fjern-reduceret-bevaegelse'
					? false
					: // eslint-disable-next-line
					  this.$route.hash === '#reduceret-bevaegelse' ||
					  (process.client &&
							localStorage.getItem('reduced-motion')) ||
					  this.$store.state.isReducedMotion,
		};
	},

	head() {
		const { solution } = this.$store.state;
		const meta = Object.assign({}, this.meta || {});
		meta.meta = meta.meta || [];
		meta.script = meta.script || [];

		!meta.meta.find((item) => item.name === 'format-detection') &&
			meta.meta.push({
				// iOS phone number formatting can lead to hydration errors resulting in a double load
				hid: 'format-detection',
				name: 'format-detection',
				content: 'telephone=no',
			});

		if (solution === 'main') {
			// SiteImprove
			meta.script.push({
				type: 'text/javascript',
				innerHTML: `
						(function() {
							var scriptActivated = false;
							var setScript = function() {
								if (!scriptActivated && window.CookieInformation && window.CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
									scriptActivated = true; // Make sure it only happens once
									/*<![CDATA[*/
									(function() {
										var sz = document.createElement('script'); sz.type = 'text/javascript'; sz.async = true;
										sz.src = '//siteimproveanalytics.com/js/siteanalyze_2566.js';
										var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sz, s);
									})();
									/*]]>*/
								}
							};
							setScript();
							window.addEventListener('CookieInformationConsentGiven', setScript);
						})()`,
				hid: 'siteimprove',
			});

			meta.meta.push({
				hid: 'facebook-domain-verification',
				name: 'facebook-domain-verification',
				content: '1fscuyxbrngqdlyczyllpuz4mfrbye',
			});
		}

		return {
			...meta,
			__dangerouslyDisableSanitizersByTagID: {
				siteimprove: ['innerHTML'],
			},
		};
	},

	computed: {
		...mapGetters(['solution']),

		doctype() {
			const doctype = getDoctypeFromSolutionAndTemplate(
				this.solution,
				this.template
			);
			if (Object.keys(doctypes).includes(doctype)) {
				return doctype;
			}
			return null;
		},
	},

	watch: {
		'theme.label': {
			immediate: true,
			handler(value) {
				if (this.template === 'FrontPage') {
					value = 'prism';
				}
				this.$store.commit('SET_COLOR_THEME', value);
			},
		},
		isReducedMotion: {
			immediate: true,
			handler(value) {
				if (process.client) {
					if (value) {
						this.$store.commit('SET_REDUCED_MOTION', true);
						document.body.classList.add('reduced-motion');
						localStorage.setItem('reduced-motion', true);
					} else {
						this.$store.commit('SET_REDUCED_MOTION', false);
						document.body.classList.remove('reduced-motion');
						localStorage.setItem('reduced-motion', false);
						localStorage.removeItem('reduced-motion');
					}
				}
			},
		},
	},
	beforeMount() {
		/* eslint-disable */
		if (process.client) {
			this.isReducedMotion =
				this.$route.hash === '#fjern-reduceret-bevaegelse'
					? false
					: this.isReducedMotion ||
					  localStorage.getItem('reduced-motion') ||
					  window.matchMedia(`(prefers-reduced-motion: reduce)`) ===
							true ||
					  window.matchMedia(`(prefers-reduced-motion: reduce)`)
							.matches === true;
		}
		/* eslint-enable */
	},
};
</script>
